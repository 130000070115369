import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import CategoryFilterAccordionItem from '~components/blocks/CategoryFilterAccordionItem'
import FilterAccordionItem from '../../blocks/FilterAccordionItem';
import SortAccordionItem from '../../blocks/SortAccordionItem';
import Section from '~components/Section'

import { CrossThick } from '../../Svg';

const ProductsFilterMobile  = forwardRef(({ 
	className, 
	brands, 
	shapes, 
	materials, 
	priceBrackets, 
	categories, 
	sortOptions, 
	onUpdate, 
	parentCategory, 
	hasPurchasable 
}, 
ref) => {

  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({
    prices: [],
    brands: [],
		shapes: [],
		materials: [],
  })
  const [selectedSortOption, setSelectedSortOption] = useState([])

  const categoriesRef = useRef()
  const filtersRef = useRef()
  const sortRef = useRef()

  const updateFromExternal = params => {
    if(params.categories && categoriesRef.current){
      categoriesRef.current.update(params.categories)
    }
    if((params.brands || params.prices || params.shapes || params.materials) && filtersRef.current){
      filtersRef.current.update(params)
    }
    if(params.sort && sortRef.current){
      sortRef.current.update(params.sort)
    }
  }

  useImperativeHandle(
    ref,
    () => ({
        update(params) {
          updateFromExternal(params)
        }
    }),
  )

  useEffect(()=> {
    const selectedOptions = {
      categories: selectedCategory,
      brands: selectedFilters.brands,
      prices: selectedFilters.prices,
			shapes: selectedFilters.shapes,
			materials: selectedFilters.materials,
			purchasableOnly: selectedFilters.purchasableOnly,
      sort: selectedSortOption,
    }
    if(onUpdate){
      onUpdate(selectedOptions)
    }
  }, [selectedCategory, selectedFilters, selectedSortOption])

  const clearAll = () => {
    categoriesRef.current.update([])
    filtersRef.current.update({ brands:[], prices:[] })
  }

  return (
    <Section
      className={className}
      css={css`
      `}
    >
			<h6 css={css`grid-column: span 12; margin-bottom: 10px;`}>Category</h6>
			 <div className="h5" css={css`grid-column: span 12; margin-bottom: 20px;`}>
            <>
            <Link 
            css={css`color: var(--grey); padding: 3px 0; display: inline-block;`} 
            activeStyle={{ color: '#000000' }}
            to={ parentCategory ? resolveLink(parentCategory._type, parentCategory.slug?.current) : '/products'}>
              All
            </Link>
            <br/>
            </>
          {categories?.map(category => (
            <>
            <Link 
              key={category.slug?.current} 
              to={resolveLink(category._type, category.slug?.current, parentCategory?.slug?.current)} 
              css={css`color: var(--grey); padding: 3px 0; display: inline-block;`}
              activeStyle={{ color: '#000000' }}>
              {category.title}
            </Link>
            <br/>
            </>
          ))}
        </div>
      {/* <CategoryFilterAccordionItem 
        options={categories} 
        onChange={value => setSelectedCategory(value)}
        selectedOptions={selectedCategory}
        ref={categoriesRef}
        css={css`
          grid-column: span 12;
        `}
      /> */}
      <FilterAccordionItem 
        brands={brands}
				shapes={shapes}
				materials={materials} 
        priceBrackets={priceBrackets}
        onChange={value => setSelectedFilters(value)}
				hasPurchasable={hasPurchasable}
        ref={filtersRef}
        css={css`
          grid-column: span 12;
        `}
      />
      <SortAccordionItem
        sortOptions={sortOptions}
        onChange={value => setSelectedSortOption(value)}
        ref={sortRef}
        css={css`
          grid-column: span 12;
        `}
      />
      <div css={css`grid-column: span 12; padding: 13px 0;`}>
        <button 
          onClick={() => clearAll()}
          className='h6'
          css={css`
            align-items: center;
            display: inline-flex;
            opacity: ${(selectedFilters.brands.length || selectedFilters.prices.length || selectedCategory.length) ? 1 : 0};
          `}
        >
          Clear All
          <CrossThick css={css`
            width: 9px;
            height: 9px;
            margin-left: 6px;
          `}/> 
        </button>
      </div>
    </Section>
  )
})

ProductsFilterMobile.propTypes = {
}

ProductsFilterMobile.defaultProps = {
  categories: [
    {
      name: `Category 1`,
      key: `2`,
      id: `2`,
    },
    {
      name: `Category 2`,
      key: `3`,
      id: `3`,
    },
    {
      name: `Category 3`,
      key: `4`,
      id: `4`,
    },
    {
      name: `Category 4`,
      key: `5`,
      id: `5`,
    }
  ],
  brands: [
    {
      name: `Brand 1`,
      key: `2`,
      id: `2`,
    },
    {
      name: `Brand 2`,
      key: `3`,
      id: `3`,
    }
  ],  
  priceBrackets: [
    {
      name: `priceBracket 1`,
      key: `2`,
      id: `2`,
    },
    {
      name: `priceBracket 2`,
      key: `3`,
      id: `3`,
    }
  ],
  sortOptions: [
    {
      name: `Sort Option 1`,
      key: `1`,
      id: `1`
    },
    {
      name: `Sort Option 2`,
      key: `2`,
      id: `2`
    },
    {
      name: `Sort Option 3`,
      key: `3`,
      id: `3`
    },
  ]
}

export default ProductsFilterMobile
