import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import {ChevronDown, CrossThick, Tick} from '~components/Svg'

const SortAccordionItem = forwardRef(({ className, selectedOptions, onChange, sortOptions }, ref) => {

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(sortOptions[0])
  const [applied, setApplied] = useState(sortOptions[0])

  const applySelected = () => {
    setApplied(selected)
    setOpen(false)
  }
  
  const clear = () => {
    setSelected(sortOptions[0])
  }

  useEffect(() => {
    selectedOptions = applied
    if(onChange){
      onChange(selectedOptions)
    }
  }, [applied])

  useImperativeHandle(
      ref,
      () => ({
          update(params) {
            setSelected(params)
            setApplied(params)
          }
      }),
  )

  return (
    <div
      className={className}
      css={css`
        border-bottom: 1px solid var(--grey); 
    `}>
      <button 
        onClick={() => setOpen(!open)}
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 12px 0;
      `}>
        <h6>Sort</h6>
        <div 
          className='h6'
          css={css`
            display: flex;
            align-items: center;
        `}>
          {applied}
          <ChevronDown css={css`
            width: 12px;
            height: 13.75px;
            transform: ${open ? `rotate(180deg)` : `rotate(0deg)` };
            transition: 0.3s transform;
            margin: 0 6px;
          `}/>
        </div>
      </button>
      <SlideDown 
        className={'my-dropdown-slidedown'} 
        css={css` 
          transition: 0.4s ease-in-out;
      `}>
        {open ? 
          <div css={css`
            padding-top: 16px;
            padding-bottom: 10px;
          `}>
            <div css={css`
              margin-bottom: 26px;
            `}>
              {sortOptions.map(option => (
              <button
                className='h5'
                key={option.id} 
                onClick={() => setSelected(option)}
                css={css`
                  color:${ selected === (option) ? `inherit` : `var(--grey)`};
                  transition: 0.2s color;
                  text-align: left;
                  display: block;
                  margin-bottom: 10px;
                  &:hover{ 
                    color: inherit;
                  }
              `}>
                {option}
              </button>
              ))}
            </div>
            { (applied !== sortOptions[0] || selected !== applied)&&
              <div css={css`
                margin-bottom: 26px;
              `}>
                { selected !== applied &&
                  <button 
                    onClick={() => applySelected()}
                    className='h6'
                    css={css`margin-right: 34px;`}
                    >
                    Apply
                    <Tick css={css`
                      width: 10px;
                      height: 7px;
                      margin-left: 6px;
                    `}/>
                  </button>
                }
                <button 
                  onClick={() => clear()}
                  className='h6'
                  css={css`
                    opacity: ${applied !== sortOptions[0] || selected !== sortOptions[0] ? `1` : `0`};
                    pointer-events: ${applied !== sortOptions[0] || selected !== sortOptions[0] ? `auto` : `none`};
                    align-items: center;
                    display: inline-flex;
                  `}
                >
                  Clear
                  <CrossThick css={css`
                    width: 9px;
                    height: 9px;
                    margin-left: 6px;
                  `}/> 
                </button>
              </div>
            }
          </div>
        : null}
      </SlideDown>
    </div>
  )
})

SortAccordionItem.propTypes = {
}

SortAccordionItem.defaultProps = {
  sortOptions: [
    {
      name: `Sort Option 1`,
      key: `1`,
      id: `1`
    },
    {
      name: `Sort Option 2`,
      key: `2`,
      id: `2`
    },
    {
      name: `Sort Option 3`,
      key: `3`,
      id: `3`
    },
  ]
}

export default SortAccordionItem
