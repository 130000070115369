import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'

const CheckBox = ({ className, id, name, initialChecked, children, onChange, hiddenName }) => {

  const [isChecked, setIsChecked] = useState(initialChecked ?? false)

  const { mobile } = breakpoints

  useEffect(() => {
    setIsChecked(initialChecked)
  }, [initialChecked])

  const handleChange = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    if(onChange){
      onChange(isChecked)
    }
  }, [isChecked])

  return (
    <div
      className={className}
      css={css`
        display: flex;
        position: relative;
        margin-bottom: 1em;

        input{
          opacity: 0;
          position: absolute;
          pointer-events: none;
        }

        label{
          margin: 0 0 0 20px;
          display: inline-block;
          cursor: pointer;
          ${mobile}{
            margin: 0 0 0 25px;
          }
          &:before{
            content: "";
            height: 12px;
            width: 12px;
            display: block;
            position: absolute;
            transition: background-color 0.3s;
            background: ${isChecked ? 'var(--black)' : 'rgba(0, 0, 0, 0,)'};
            border: 1px solid var(--black);
            left: 0;
            top: 0;
            border-radius: 100px;
            ${mobile}{
              height: 16px;
              width: 16px;
              top: -3px;
            }
          }
        }
      `}
    >
        <input type="checkbox" id={id} onChange={() => handleChange()} checked={initialChecked}/>
        <input type="hidden" value={isChecked} name={hiddenName}/>
        <label htmlFor={id} className="h6">
          {children}
        </label>
    </div>
  )
}

CheckBox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  initialChecked: PropTypes.bool,
  label: PropTypes.node
}

CheckBox.defaultProps = {
  id: 'example',
  name: 'example',
  initialChecked: false,
  children: 'Subscribe to our newsletter to receive news about promotions, products and more.'
}

export default CheckBox
