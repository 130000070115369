import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const MultiSelect = forwardRef(({ className, selectedOptions, options, onChange }, ref) => {

  const {mobile} = breakpoints

  const [selected, setSelected] = useState(selectedOptions.map(option => option.title))
  

  const updateSelectedValue = selectedOption => {
    const index = selected.indexOf(selectedOption)
    if (index > -1){
      const updated = selected
      updated.splice(index, 1)
      setSelected([...updated])
    } else (
      setSelected([...selected, selectedOption])
    )
  }

  useImperativeHandle(
      ref,
      () => ({
          update(params) {
            setSelected(params)
          }
      }),
  )

  useEffect(() => {
    selectedOptions = selected
    if(onChange){
      onChange(selectedOptions)
    }
  }, [selected])
  
  return (
    <div className={className}>
        <button 
          onClick={()=> setSelected([])}
          className={`h5`}
          css={css`
            color:${ selected.length === 0 ? `inherit` : `var(--grey)`};
            transition: 0.2s color;
            text-align: left;
            display: block;
            margin-bottom: 1px;
            &:hover{ 
              color: inherit;
            }
            ${mobile}{
              margin-bottom: 10px;
            }
            `}>
          All
        </button>
        {options.map(option => (
        <button
          className={`h5`}
          key={option.id} 
          onClick={() => updateSelectedValue(option.title)}
          css={css`
            color:${ selected === null ? `inherit` : selected.includes(option.title) ? `inherit` : `var(--grey)`};
            transition: 0.2s color;
            text-align: left;
            display: block;
            margin-bottom: 1px;
            &:hover{ 
              color: inherit;
            }
            ${mobile}{
              margin-bottom: 10px;
            }
        `}>
          {toTitleCase(option.title)}
        </button>
      ))}
    </div>
  )
})

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
  })),
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
}

MultiSelect.defaultProps = {
  selectedOptions: [
    {
      title: `Events`,
    },
  ],
  options: [
    {
      key: `2`,
      title: `News`,
      id: `option2`,
    },
    {
      key: `3`,
      title: `Brand Profile`,
      id: `option3`,
    },
    {
      key: `4`,
      title: `Events`,
      id: `option4`,
    },
    {
      key: `5`,
      title: `Category`,
      id: `option5`,
    },
  ],
  onChange: value => console.log('onChangeResult', value),
}

export default MultiSelect