import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import {ChevronDown, CrossThick, Tick} from '~components/Svg'

const CategoryFilterAccordionItem = forwardRef(({ className, options, selectedOptions, onChange }, ref) => {

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [applied, setApplied] = useState([])

  const updateSelectedValues = selectedOption => {
    const index = selected.indexOf(selectedOption)
    if (index > -1){
      const updated = selected
      updated.splice(index, 1)
      setSelected([...updated])
    } else (
      setSelected([...selected, selectedOption])
    )
  }

  useImperativeHandle(
      ref,
      () => ({
          update(params) {
            setSelected(params)
            setApplied(params)
          }
      }),
  )

  const clear = () => {
    setSelected([])
  }

  const applySelected = () => {
    setApplied(selected)
    setOpen(false)
  }

  useEffect(() => {
    selectedOptions = applied
    if(onChange){
      onChange(selectedOptions)
    }
  }, [applied])

  return (
    <div
      className={className}
      css={css`
        border-bottom: 1px solid var(--grey); 
    `}>
      <button 
        onClick={() => setOpen(!open)}
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 12px 0;
      `}>
        <h6>Category</h6>
        <div 
          className='h6'
          css={css`
            display: flex;
            align-items: center;
        `}>
          {applied.length === 0 ? `All` : `${applied.length} Applied`}
          <ChevronDown css={css`
            width: 12px;
            height: 13.75px;
            transform: ${open ? `rotate(180deg)` : `rotate(0deg)` };
            transition: 0.3s transform;
            margin: 0 6px;
          `}/>
        </div>
      </button>
      <SlideDown 
        className={'my-dropdown-slidedown'} 
        css={css` 
          transition: 0.4s ease-in-out;
      `}>
        {open ? 
          <div css={css`
            padding-top: 16px;
            padding-bottom: 10px;
          `}>
            <div css={css`
              margin-bottom: 26px;
            `}>
              <button 
                onClick={()=> setSelected([])}
                css={css`
                color:${ selected.length === 0 ? `inherit` : `var(--grey)`};
                transition: 0.2s color;
                text-align: left;
                display: block;
                margin-bottom: 10px;
                &:hover{ 
                  color: inherit;
                }
              `}>
                All 
              </button>
              {options.map(option => (
              <button
                key={option.id} 
                onClick={() => updateSelectedValues(option.title)}
                css={css`
                  color:${ selected === null ? `inherit` : selected.includes(option.title) ? `inherit` : `var(--grey)`};
                  transition: 0.2s color;
                  text-align: left;
                  display: block;
                  margin-bottom: 10px;
                  &:hover{ 
                    color: inherit;
                  }
              `}>
                {toTitleCase(option.title)}
              </button>
              ))}
            </div>

              { selected !== applied || selected.length >= 1?
                <div css={css`
                  transition: 0.3s color;
                  margin-bottom: 26px;
                `}>
                  {selected !== applied && 
                    <button 
                      onClick={() => applySelected()}
                      className='h6'
                      css={css`
                        margin-right: 34px;
                        opacity: ${selected !== applied ? `1` : `0`};
                        pointer-events: ${selected !== applied ? `auto` : `none`};
                      `}
                      >
                      Apply
                      <Tick css={css`
                        width: 10px;
                        height: 7px;
                        margin-left: 6px;
                      `}/>
                    </button>
                  }
                  <button 
                    onClick={() => clear()}
                    className='h6'
                    css={css`
                      opacity: ${selected.length >= 1 ? `1` : `0`};
                      pointer-events: ${selected.length >= 1 ? `auto` : `none`};
                      align-items: center;
                      display: inline-flex;
                    `}
                  >
                    Clear
                    <CrossThick css={css`
                      width: 9px;
                      height: 9px;
                      margin-left: 6px;
                    `}/> 
                  </button>
                </div>
                :
                null
              }
          </div>
        : null}
      </SlideDown>
    </div>
  )
})

CategoryFilterAccordionItem.propTypes = {
  options: PropTypes.array,
}

CategoryFilterAccordionItem.defaultProps = {
  selectedOptions: [],
  options: [
    {
      key: `2`,
      title: `News`,
      id: `option2`,
    },
    {
      key: `3`,
      title: `Brand Profile`,
      id: `option3`,
    },
    {
      key: `4`,
      title: `Events`,
      id: `option4`,
    },
    {
      key: `5`,
      title: `Category`,
      id: `option5`,
    },
  ],
}

export default CategoryFilterAccordionItem
